<template>
  <div>
    <section v-for="block in blocks" :key="block.id">
      <news-bar v-if="block.title === 'Nieuws'"/>
      <usp-bar v-else-if="block.title === 'Sterke punten'"/>
      <quick-links v-else-if="block.title === 'Snel naar'"/>
      <faq-block v-else-if="block.title === 'Veelgestelde vragen'" :text="block.text"/>
      <agenda-bar v-else-if="block.title === 'Agenda'"/>
      <quote v-else-if="block.title === 'Ervaring'"/>
      <partners v-else-if="block.title === 'Partners'"/>
      <exsamen v-else-if="block.title === 'Exsamen'" :block="block"/>
      <illustration-bg v-else-if="block.title === 'Uitgelicht nieuwsbericht'"/>
      <themadag v-else-if="block.title === 'Themadag'"/>
      <meet-us-bar v-else-if="block.title === 'Ontmoet examenwerk'" :item="block"/>
      <text-illustration v-else-if="block.title === 'Zo maken wij goede examens'" :item="block"/>
      <default-text-block v-else :item="block"/>
    </section>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  components: {
    Partners: () => import('~/components/fullwidth-blocks/Partners'),
    Exsamen: () => import('~/components/fullwidth-blocks/ExsamenPartner'),
    Quote: () => import('~/components/fullwidth-blocks/Quote'),
    AgendaBar: () => import('~/components/agenda/AgendaBar'),
    FaqBlock: () => import('~/components/fullwidth-blocks/FaqBlock'),
    QuickLinks: () => import('~/components/fullwidth-blocks/QuickLinks'),
    UspBar: () => import('~/components/fullwidth-blocks/UspBar'),
    NewsBar: () => import('~/components/news/NewsBar'),
    IllustrationBg: () => import('~/components/fullwidth-blocks/IllustrationBg'),
    Themadag: () => import('~/components/fullwidth-blocks/Themadag'),
    MeetUsBar: () => import('~/components/MeetUsBar'),
    TextIllustration: () => import('~/components/fullwidth-blocks/TextIllustration'),
    DefaultTextBlock: () => import('~/components/DefaultTextBlock'),
  },
  computed: {
    ...mapState({
      blocks: state => state.blocks,
    }),
  },
};
</script>
