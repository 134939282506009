<template>
  <footer class="pt-10">
    <div class="bg-primary pt-10 pb-10 md:pb-6 relative">
      <button class="gradient-square" @click="scrollToTop">
        <div class="icon-container">
          <font-awesome-icon :icon="['far', 'arrow-right']"/>
        </div>
      </button>

      <div class="container flex flex-wrap justify-between">
        <section id="contact-info" class="text-white flex flex-col pb-10 md:pb-0 w-full md:w-auto md:flex-shrink">
          <div class="text-xl font-heading font-semibold text-white">
            Contact
          </div>
          <address class="pt-3 leading-normal text-base font-light font-heading not-italic">
            {{ settings.name }}<br>
            {{ settings.address }}<br>
            {{ settings.postalcode }}  {{ settings.city }}<br>
            <a :href="`tel:${settings.phone}`" class="text-white leading-normal text-base font-light font-heading">{{ settings.phone }}</a><br>
            <a :href="`mailto:${settings.email}`" class="text-white leading-normal text-base font-light font-heading">{{ settings.email }}</a><br>
            <a :href="settings.linkedin" target="_blank" rel="nofollow noopener" class="text-white">
              <font-awesome-icon :icon="['fab', 'linkedin-in']" aria-label="LinkedIn"/>
            </a>
          </address>
        </section>
        <section v-for="group in menuFooter" :key="group.text" class="text-white flex flex-col pb-10 md:pb-0 w-full md:w-auto md:flex-shrink">
          <div class="text-xl font-heading font-semibold text-white">
            {{ group.text }}
          </div>
          <div v-if="group.children">
            <div v-for="child in group.children" :key="child.url" class="leading-normal text-base font-light font-heading not-italic">
              <a v-if="isExternal(child.url)" :href="child.url" :target="child.target" rel="noopener" class="text-white">{{ child.text }}</a>
              <nuxt-link v-else :to="child.url" class="text-white">{{ child.text }}</nuxt-link>
            </div>
          </div>
        </section>
        <subscribe-form/>
      </div>
    </div>

    <section class="bg-white">
      <div class="container flex justify-start items-center">
        <a href="https://www.exsamen.nl/" target="_blank" rel="nofollow" class="block">
          <img :src="require('~/assets/img/logo-exsamen.png')" alt="Exsamen" class="h-12">
        </a>
        <ul class="container py-4 font-heading font-light text-primary">
          <li v-for="link in menuOther" :key="link.url" class="pr-4 inline-block">
            <nuxt-link :to="link.url" class="text-sm">{{ link.text }}</nuxt-link>
          </li>
        </ul>
      </div>
    </section>
  </footer>
</template>

<script>
import {mapState} from 'vuex';
import SubscribeForm from '~/components/footer/SubscribeForm';

export default {
  components: {
    SubscribeForm,
  },
  computed: {
    ...mapState({
      settings: state => state.settings,
      menuOther: state => state.menuOther,
      menuFooter: state => state.menuFooter,
    }),
  },
  methods: {
    scrollToTop() {
      document.body.scrollIntoView({behavior: 'smooth', block: 'start'});
    },
    isExternal(url) {
      return url.charAt(0) !== '/';
    },
  },
};
</script>

<style lang="sass" scoped>
$square-size: 46px

button:focus
  outline: none

/deep/ .gradient-square
  position: absolute
  top: 0
  left: 50%
  transform: translateX(-50%) translateY(-50%) rotate(-315deg)
  width: $square-size
  height: $square-size
  border: solid 4px rgb(255, 255, 255)
  background-image: linear-gradient(225deg, rgb(0, 142, 148) 0%, rgb(151, 195, 76) 100%)
  .icon-container
    text-align: center
    width: $square-size
    height: $square-size
    transform: rotate(315deg)
    svg
      width: 1rem
      color: white
      margin-top: 10px
      transform: rotate(-90deg)
</style>
