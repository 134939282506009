<template>
  <div>
    <random-review/>
    <upcoming-events/>
  </div>
</template>

<script>
import UpcomingEvents from '~/components/UpcomingEvents';
import RandomReview from '~/components/RandomReview';

export default {
  components: {
    UpcomingEvents,
    RandomReview,
  },
  // watch: {
  //   $route() {
  //     this.$store.dispatch('getReview');
  //   },
  // },
};
</script>
