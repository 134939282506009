<template>
  <transition name="slide-up">
    <div v-if="notificationBar" class="bg-secondary">
      <div class="container flex justify-between items-center py-3 text-white">
        <div class="flex items-center text-xl leading-none">
          <font-awesome-icon :icon="['fal', 'exclamation-triangle']" class="mr-4"/>
          <span class="text-sm font-heading font-light" v-html="announcement"/>
        </div>
        <div class="text-base leading-none">
          <button type="button" class="focus:outline-none" @click="closeNotification">
            <font-awesome-icon :icon="['fal', 'times']"/>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data: function () {
    return {
      notificationBar: false,
    };
  },
  computed: {
    ...mapState({
      announcement: state => state.announcement,
    }),
  },
  created() {
    if (this.announcement.length !== 0) {
      this.notificationBar = true;
    }
  },
  methods: {
    closeNotification() {
      this.notificationBar = !this.notificationBar;
    },
  },
};
</script>
