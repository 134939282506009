<template>
  <div class="flex flex-col min-h-screen">
    <toolbar class="z-50"/>
    <main class="mt-18 sm:mt-0 flex-grow">
      <notification-bar/>
      <nuxt class="z-30"/>
      <blocks/>
    </main>
    <app-footer class="z-40"/>
  </div>
</template>

<script>
import NotificationBar from '~/layouts/partials/NotificationBar';
import AppFooter from '~/layouts/partials/Footer';
import Toolbar from '~/layouts/partials/Toolbar';
import Blocks from '~/layouts/partials/Blocks';

export default {
  components: {
    NotificationBar,
    AppFooter,
    Toolbar,
    Blocks,
  },
};
</script>
