<template>
  <div id="newsletter-subscribe" class="flex flex-col w-full sm:w-3/5 md:w-2/5 lg:w-1/4">
    <div class="text-xl font-heading font-semibold text-white">
      Meld je aan voor de nieuwsbrief
    </div>
    <div v-if="success" class="text-green">
      Je bent succesvol aangemeld voor de nieuwsbrief.
    </div>
    <div v-else-if="error" class="text-red">
      Er is iets fout gegaan tijdens het aanmelden.
    </div>
    <div class="pt-3">
      <form @submit.prevent="submit">
        <div class="w-full">
          <input
            v-model="subscriber.name"
            type="text"
            placeholder="Vul hier uw naam in"
            aria-label="Vul hier uw naam in"
            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
          >
          <div v-if="errors.name" class="text-xs font-bold text-red -mt-1 mb-3">* {{ errors.name[0] }}</div>
        </div>
        <div class="w-full pb-1">
          <input
            v-model="subscriber.email"
            type="email"
            placeholder="Vul hier uw e-mailadres in"
            aria-label="Vul hier uw e-mailadres in"
            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
          >
          <div v-if="errors.email" class="text-xs font-bold text-red -mt-1 mb-3">* {{ errors.email[0] }}</div>
        </div>
        <button :disabled="loading" @click="submit">
          <btn :button="true">Verstuur</btn>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import Btn from '~/components/base/Btn';

export default {
  components: {Btn},
  data() {
    return {
      success: null,
      error: null,
      loading: false,
      subscriber: {
        name: '',
        email: '',
      },
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        await this.$axios.post('/newsletter', this.subscriber);

        this.subscriber = {
          name: '',
          email: '',
        };

        this.success = true;
      } catch (e) {
        this.error = true;
      }
      this.loading = false;
    },
  },
};
</script>
