<template>
  <div class="flex flex-col min-h-screen">
    <div class="container flex flex-col md:w-3/5 py-20">
      <h2 v-if="error.statusCode === 410 || error.statusCode === 404" class="page-title">{{ page.title }}</h2>
      <h2 v-else class="page-title">Er is een fout opgetreden</h2>
      <div class="page-content" v-html="page.tekst"/>
      <nuxt-link to="/" class="mt-8">
        <font-awesome-icon :icon="['far', 'arrow-left']" class="text-secondary mr-1 sm:mr-4"/>
        Terug naar de Homepage
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    error: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: mapState(['page']),
  async created() {
    await this.$store.dispatch('getPage', '404');
  },
  head() {
    return {
      title: this.page.meta ? this.page.meta.title : this.page.title,
      meta: [
        this.page.meta && this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
      ],
    };
  },
};
</script>
