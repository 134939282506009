<template>
  <form class="flex justify-end" @submit.prevent="submit">
    <input
      v-model="query"
      type="text"
      placeholder="Zoeken..."
      aria-label="Typ hier om te zoeken"
      class="focus:outline-none text-xs px-2 border border-secondary"
    >
    <button type="submit" aria-label="Start zoeken" class="focus:outline-none text-center items-center flex h-10 px-4 text-xs font-body uppercase hover:text-primary transition duration-200 ease-in-out focus:text-secondary bg-secondary text-white">
      <font-awesome-icon :icon="['far', 'arrow-right']" aria-hidden="true"/>
    </button>
  </form>
</template>

<script>
export default {
  data: () => ({
    query: '',
  }),
  methods: {
    submit() {
      if (this.query) {
        this.$router.push(`/zoekresultaten?q=${this.query}`);
      }
    },
  },
};
</script>
