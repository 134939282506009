<template>
  <div>
    <h2>Agenda</h2>
    <div>
      <div v-for="event in upcomingEvents" :key="event.title" :to="`/agenda/${event.permalink}`">
        <div>{{ $moment(event.date).format('LL') }}</div>
        <div>{{ event.title }}</div>
      </div>
      <nuxt-link :to="`/agenda`">Bekijk alle activiteiten</nuxt-link>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState({
      upcomingEvents: state => state.upcomingEvents,
    }),
  },
};
</script>
