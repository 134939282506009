<template>
  <component
    :is="externalLink ? 'a' : 'nuxt-link'"
    :to="button ? '' : link"
    :href="button ? '' : link"
    :target="target"
    :class="['bg-' + color, 'text-' + txtColor, 'hover:bg-' + hoverColor, 'hover:text-' + hoverTxtColor, text ? 'px-0' : 'px-3']"
    class="flex w-fit-content font-heading text-base items-center h-10 transition duration-300 ease-in-out"
  >
    <template v-if="icon">
      <font-awesome-icon :icon="['fal', 'arrow-right']" class="mr-2"/>
    </template>
    <slot>{{ label }}</slot>
  </component>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    icon: {
      type: Boolean,
      default: true,
    },
    button: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: 'home',
    },
    text: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '_self',
    },
    label: {
      type: String,
      default: 'Meer info',
    },
    color: {
      type: String,
      default: 'yellow',
    },
    txtColor: {
      type: String,
      default: 'primary',
    },
    hoverColor: {
      type: String,
      default: 'secondary',
    },
    hoverTxtColor: {
      type: String,
      default: 'white',
    },
  },
  computed: {
    externalLink() {
      return typeof this.link === 'string' && this.link.match(/https?:\/\//);
    },
  },
};
</script>
