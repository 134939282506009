<template>
  <div>
    <div>
      {{ review.review }}
    </div>
    <div>
      {{ review.name }}, {{ review.function }} namens {{ review.organization }}
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState({
      review: state => state.review,
    }),
  },

};
</script>
