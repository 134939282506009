import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faChevronDown,
  faChevronUp,
  faLockAlt,
  faChevronRight,
  faChevronLeft,
  faArrowRight as faArrowRightReg,
  faArrowLeft as faArrowLeftReg,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faBars,
  faArrowRight,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faAward,
  faShareAlt,
  faGlobe,
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';

import {
  faInstagram,
  faLinkedinIn,
  faTelegramPlane,
  faTwitter,
  faFacebookF,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

config.autoAddCss = false;

library.add(
  faArrowLeft,
  faArrowRight,
  faInstagram,
  faLinkedinIn,
  faTelegramPlane,
  faTwitter,
  faFacebookF,
  faYoutube,
  faChevronDown,
  faLockAlt,
  faBars,
  faArrowRight,
  faAward,
  faShareAlt,
  faGlobe,
  faChevronRight,
  faChevronLeft,
  faArrowRightReg,
  faArrowLeftReg,
  faArrowDown,
  faArrowUp,
  faExclamationTriangle,
  faTimes,
  faChevronUp,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
