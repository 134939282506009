export const state = () => ({
  release: process.env.RELEASE,
  page: {},
  blocks: [],
  menuMain: {},
  menuFooter: {},
  menuOther: {},
  menuQuick: {},
  settings: {},
  announcement: '',
  upcomingEvents: {},
  review: {},
  questions: [],
  highlightedQuestions: [],
  news: [],
  partners: [],
});

export const actions = {
  async nuxtServerInit({commit, dispatch}) {
    const [
      settings,
      menus,
      announcement,
      events,
      questions,
      news,
      partners,
    ] = await Promise.all([
      this.$axios.get('/cached/settings'),
      this.$axios.get('/cached/menus'),
      this.$axios.get('/announcement'),
      this.$axios.get('/events/upcoming'),
      this.$axios.get('/cached/questions'),
      this.$axios.get('/news/highlighted'),
      this.$axios.get('/cached/partners'),
    ]);

    commit('SET_SETTINGS', settings.data);
    commit('SET_ANNOUNCEMENT', announcement.data);
    commit('SET_MENU_MAIN', menus.data.main);
    commit('SET_MENU_FOOTER', menus.data.footer);
    commit('SET_MENU_OTHER', menus.data.other);
    commit('SET_MENU_QUICK', menus.data.quick);
    commit('SET_UPCOMING_EVENTS', events.data.data);
    commit('SET_QUESTIONS', questions.data.data);
    commit('SET_HIGHLIGHTED_QUESTIONS', questions.data.data);
    commit('SET_NEWS', news.data.data);
    commit('SET_PARTNERS', partners.data.data);

    dispatch('getReview');
  },

  async getPage({commit}, slug = '') {
    const {data} = await this.$axios.get(`/page?segments=${slug}`);

    commit('SET_PAGE', data.data);
  },

  async getReview({commit}) {
    const {data} = await this.$axios.get(`/review`);

    commit('SET_REVIEW', data.data);
  },
};

export const mutations = {
  SET_PAGE(state, payload) {
    state.page = payload;
    state.blocks = payload.blokken || [];
  },
  SET_MENU_MAIN(state, payload) {
    state.menuMain = payload;
  },
  SET_MENU_FOOTER(state, payload) {
    state.menuFooter = payload;
  },
  SET_MENU_OTHER(state, payload) {
    state.menuOther = payload;
  },
  SET_MENU_QUICK(state, payload) {
    state.menuQuick = payload;
  },
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_ANNOUNCEMENT(state, payload) {
    state.announcement = payload;
  },
  SET_UPCOMING_EVENTS(state, payload) {
    state.upcomingEvents = payload;
  },
  SET_REVIEW(state, payload) {
    state.review = payload;
  },
  SET_QUESTIONS(state, payload) {
    state.questions = payload;
  },
  SET_HIGHLIGHTED_QUESTIONS(state, payload) {
    state.highlightedQuestions = payload.filter(question => question.highlighted);
  },
  SET_NEWS(state, payload) {
    state.news = payload;
  },
  SET_PARTNERS(state, payload) {
    state.partners = payload;
  },
};
