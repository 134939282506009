<template>
  <nav class="gradient-border top-0 fixed sm:relative w-full">
    <div class="pb-2px">
      <div class="w-full bg-white">
        <div
          class="container flex py-3 lg:py-0 h-auto items-center justify-between flex-wrap"
        >
          <section id="logo" class="relative items-center flex flex-1">
            <nuxt-link to="/" class="cursor-pointer">
              <img
                src="~/assets/img/logo.png"
                alt="Examenwerk"
                class="object-contain object-left h-12 md:h-18 w-full"
              >
            </nuxt-link>
          </section>

          <!-- Mobile menu -->
          <section class="mobile-menu flex lg:hidden items-center">
            <button type="button" class="focus:outline-none" @click="toggle">
              <font-awesome-icon :icon="['fal', 'bars']" size="2x"/>
            </button>
            <template v-if="openMenu">
              <div class="transition duration-500 ease-in-out mobile-menu--overlay bg-secondary fixed left-0 right-0 bottom-0">
                <div class="container">
                  <ul class="relative">
                    <li
                      v-for="(link, index) in menuMain"
                      :key="link.url"
                      :class="{'border-b border-white': index !== menuMain.length - 1}"
                    >
                      <div v-if="link.children" class="relative z-40 font-body py-6 block text-sm leading-none">
                        <div class="flex justify-between text-white px-3">
                          <nuxt-link class="font-light text-white" :to="link.url">
                            {{ link.text }}
                          </nuxt-link>
                          <div>
                            <font-awesome-icon :icon="['far', activeChildren.first === link.url ? 'chevron-up' : 'chevron-down']" size="1x" @click="setActiveChild(link.url)"/>
                          </div>
                        </div>
                        <ul v-if="activeChildren.first === link.url" class="pl-3">
                          <li v-for="child in link.children" :key="child.url" class="pt-6">
                            <div class="flex justify-between text-white px-3">
                              <nuxt-link class="font-light text-white" :to="child.url">
                                {{ child.text }}
                              </nuxt-link>
                              <div v-if="child.children">
                                <font-awesome-icon :icon="['far', activeChildren.second === child.url ? 'chevron-up' : 'chevron-down']" size="1x" @click="setActiveGrandChild(child.url)"/>
                              </div>
                            </div>
                            <ul v-if="activeChildren.second === child.url" class="pl-3">
                              <li v-for="grandChild in child.children" :key="grandChild.url" class="px-3 pt-6">
                                <div class="text-white">
                                  <nuxt-link class="font-light text-white" :to="grandChild.url">
                                    {{ grandChild.text }}
                                  </nuxt-link>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <nuxt-link
                        v-else
                        :to="link.url || ''"
                        class="relative z-40 font-body py-6 block text-sm text-white leading-none"
                      >
                        <div class="flex justify-between px-3">
                          <div class="font-light">
                            {{ link.text }}
                          </div>
                          <div>
                            <font-awesome-icon :icon="['far', 'chevron-right']" size="1x"/>
                          </div>
                        </div>
                      </nuxt-link>
                    </li>
                  </ul>
                </div>
                <div class="bg-gray-lighter py-4">
                  <ul class="container">
                    <li
                      v-for="(link, index) in topmenu.slice(0, 3)"
                      :key="link.url"
                      :class="{'border-b border-white': index !== topmenu.length - 2}"
                    >
                      <nuxt-link
                        :to="link.url || ''"
                        class="relative z-40 font-body py-6 block text-sm text-primary leading-none"
                      >
                        <div class="px-3 text-xs font-light uppercase">
                          {{ link.title }}
                        </div>
                      </nuxt-link>
                    </li>
                  </ul>
                </div>
                <search-field class="my-6"/>
              </div>
            </template>
          </section>
          <!-- Mobile menu end -->

          <!--// Main menu //-->
          <nav class="hidden lg:flex h-28 flex-col justify-between text-right pb-3">
            <ul id="top-menu">
              <li class="h-full items-start justify-end flex-row flex">
                <search-field class="mr-3"/>
                <nuxt-link
                  v-for="link in topmenu"
                  :key="link.title"
                  :to="link.url || ''"
                  class="relative text-primary text-center items-center flex h-10 px-4 bg-gray-lighter text-xs font-body uppercase hover:text-secondary transition duration-200 ease-in-out focus:text-secondary last:ml-3 last:bg-secondary last:text-white"
                >
                  <template v-if="link.icon">
                    <font-awesome-icon :icon="['far', 'lock-alt']" class="mr-2"/>
                  </template>
                  {{ link.title }}
                </nuxt-link>
              </li>
            </ul>

            <ul id="main-menu" class="text-sm flex space-x-4">
              <li v-for="link in menuMain" :key="link.text">
                <a
                  v-if="link.target === '_blank'"
                  target="_blank"
                  rel="noopener noreferrer"
                  :href="link.url"
                  class="relative z-40 inline-block text-sm font-body leading-none nav hover:text-secondary focus:text-secondary transition duration-200 ease-in-out md:ml-4 lg:ml-8 lg:mt-0 first:ml-0 "
                  @click.stop
                >{{ link.text }}</a>
                <nuxt-link
                  v-else
                  :to="link.url || ''"
                  class="relative z-40 inline-block text-sm font-body leading-none nav hover:text-secondary focus:text-secondary transition duration-200 ease-in-out md:ml-4 lg:ml-8 lg:mt-0 first:ml-0 "
                >
                  {{ link.text }}
                  <template v-if="link.children">
                    <font-awesome-icon :icon="['far', 'chevron-down']" class="ml-1"/>
                    <ul class="nav__content absolute w-64 left-0 shadow-submenu bg-white py-5">
                      <li v-for="(sub, index) in link.children" :key="sub.url" class="nav-child relative bg-white text-left px-6">
                        <a
                          v-if="sub.target === '_blank'"
                          target="_blank"
                          rel="noopener noreferrer"
                          :href="sub.url"
                          class="no-underline font-body flex justify-between text-sm text-primary-lighter hover:text-secondary"
                          @click.stop
                        >
                          {{ sub.text }}
                        </a>
                        <nuxt-link
                          v-else
                          :to="sub.url"
                          class="no-underline font-body flex justify-between text-sm text-primary-lighter hover:text-secondary"
                        >
                          {{ sub.text }}
                          <template v-if="sub.children">
                            <font-awesome-icon :icon="['far', 'chevron-right']" class="ml-1"/>
                            <ul class="absolute w-64 shadow-submenu bg-white py-5">
                              <li v-for="(subChildren, i) in sub.children" :key="subChildren.url" class="relative bg-white text-left px-6">
                                <a
                                  v-if="subChildren.target === '_blank'"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  :href="subChildren.url"
                                  class="no-underline font-body text-sm text-primary-lighter hover:text-secondary"
                                  @click.stop
                                >{{ subChildren.text }}</a>
                                <nuxt-link
                                  v-else
                                  :to="subChildren.url"
                                  class="no-underline font-body text-sm text-primary-lighter hover:text-secondary"
                                >
                                  {{ subChildren.text }}
                                </nuxt-link>
                                <hr v-if="i !== sub.children.length - 1" class=" my-4 text-gray-light">
                              </li>
                            </ul>
                          </template>
                        </nuxt-link>
                        <hr v-if="index !== link.children.length - 1" class=" my-4 text-gray-light">
                      </li>
                    </ul>
                  </template>
                </nuxt-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapState} from 'vuex';
import SearchField from '~/components/SearchField';

export default {
  components: {SearchField},
  data() {
    return {
      activeChildren: {
        first: null,
        second: null,
      },
      openMenu: false,
      topmenu: [
        {
          title: 'Vacatures',
          url: '/vacatures',
        },
        {
          title: 'Nieuws',
          url: '/nieuws',
        },
        {
          title: 'Agenda',
          url: '/agenda',
        },
        {
          title: 'Naar exameninstrumenten',
          url: '/exameninstrumenten',
          icon: true,
          nohover: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      menuMain: state => state.menuMain,
    }),
  },
  watch: {
    '$route'() {
      this.openMenu = false;
    },
  },
  methods: {
    toggle() {
      this.openMenu = !this.openMenu;
    },
    setActiveChild(link) {
      if (this.activeChildren.first === link) {
        this.activeChildren = {
          first: null,
          second: null,
        };
      } else {
        this.activeChildren.first = link;
      }
    },
    setActiveGrandChild(link) {
      if (this.activeChildren.second === link) {
        this.activeChildren.second = null;
      } else {
        this.activeChildren.second = link;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  &:hover > ul {
    display: block;
  }
  &__content {
    display: none;
    margin-top: 17px;

    &::before {
      position: absolute;
      content: '';
      top: -17px;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .nav-child {
      ul {
        display: none;
      }
      &:hover ul {
        z-index: 2;
        display: block;
        left: 100%;
        top: -18px;
        &::before {
          position: absolute;
          content: '';
          background-color: white;
          left: -5px;
          width: 100px;
          top: 0;
          bottom: 0;
        }
      }
    }
  }
}

#main-menu a:first-child {
  margin-left: 0 !important;
}

#top-menu {
  > li > a:last-child:hover {
    color: theme('colors.primary.default') !important;
  }
}

.mobile-menu {
  &--overlay {
    overflow-y: scroll;
    top: 74px;
    z-index: -1;
    animation: slide-down .2s ease-in-out;
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
